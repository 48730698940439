<template>
  <div class="col-lg-12 col-md-12 col-sm-12">
    <b-card-group v-if="cardInfo" class="">
      <div
        :class="!isSingleCard ? 'm-1' : ''"
        v-for="(card, index) in cardInfo"
        :key="index"
      >
        <b-card
          class="text-center planSelected"
          :class="card.planOpted ? 'selected-plan' : ''"
          :tabindex="index"
          id="card"
        >
          <div class="mb-5">
            <div :style="backgroundStyle(index)">
              <p class="pt-4">{{ card.name }}</p>
              <b-badge
                v-if="card.id == 'business'"
                class="p-2 px-3 text-uppercase position-absolute"
                style="top:-30px;left:0;background:#184ed5"
                >Recommended</b-badge
              >
              <span class="under-line mx-auto mb-4"></span>
              <b-card-text class="sub-title">{{ card.Subtitle }}</b-card-text>
              <b-card-text class="price-title">
               {{ "₹ "}} {{ card.Rate }} {{ "/ month" }}
                <span v-if="card.id != 'basic'" class="star">*</span>
              </b-card-text>
              <b-card-text class="card-inner-text mb-3 pb-3">
                {{ card.Description }}
              </b-card-text>
            </div>

            <!-- <span class="under-line-medium mx-auto mb-4 mt-4"></span> -->
            <div class="p-3" v-if="card.id != 'custom'">
              <b-card-text
                class="card-desc-text text-left"
                v-for="(desc, descIndex) in card.cardDescList"
                :key="descIndex"
              >
                <span v-if="descIndex < cardDescListLength" class="desc-item">
                  <div class="pl-4">{{ desc }}</div>
                </span>
              </b-card-text>
            </div>

            <b-form-checkbox-group
              v-if="card.id == 'custom'"
              id="checkbox-group-1"
              :options="option"
              name="flavour-1"
              v-model="selectOrgPlan"
            ></b-form-checkbox-group>
            <span
              v-if="descIndex < cardDescListLength && card.id == 'custom'"
              class="desc-item"
            >
              <div class="pl-4">{{ desc }}</div>
            </span>

            <!-- <b-card-text
              v-if="cardDescListLength != card.cardDescList.length"
              class="view-more text-center mt-1"
              @click="viewMoreField(card.cardDescList)"
            >
              <span>View more</span>
              <img src="../assets/view-more.svg" class="ml-2" />
            </b-card-text> -->
          </div>
          <div class="my-4">
            <b-button
              :style="backgroundStyle(index)"
              v-if="card.planOpted"
              class="current-plan"
              variant="primary"
            >
              <template>
                <img src="../assets/verified-white.svg" class="mr-2" />SELECTED
              </template>
            </b-button>
            <b-button
              class="current-plan"
              :style="backgroundStyle(index)"
              variant="primary"
              @click="selectPlan(card)"
              v-else
              >{{ card.buttonText }}</b-button
            >
          </div>
        </b-card>
        <!-- <div class="form-row mt-5 mb-2" v-if="index == cardInfo.length - 1">
          <b-button class="button-next" @click="goToNextPage" :disabled="!isPlanSelected"
            >NEXT</b-button
          >
          </div>-->
      </div>
    </b-card-group>
  </div>
</template>

<script>
export default {
  props: [
    "cardInfo",
    "showbutton",
    "showButtonInCard",
    "isSingleCard",
    "isPlanUpgrade",
  ],
  data() {
    return {
      cardDescListLength: 5,
      selectedPlan: "",
      option: [
        {
          text: "Supports upto 100 devices per account",
          value: "Supports upto 100 devices per account",
        },
        {
          text: "Zero touch self provisioning",
          value: "Zero touch self provisioning",
        },

        {
          text: "Shared instances/Multi Tentant",
          value: "Shared instances/Multi Tentant",
        },
        { text: "For small enterprises", value: "For small enterprises" },
        { text: "Silver support", value: "Silver support" },
      ],
      selectOrgPlan: [
        "Shared instances/Multi Tentant",
        "Silver support",
        "For small enterprises",
      ],
      // userRole: "",
    };
  },
  // computed: {
  //   isPlanSelected() {
  //     return this.selectedPlan != "";
  //   },
  // },

  methods: {
    // background color
    backgroundStyle(index) {
      if (index === 0) {
        return "background:linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%) !important";
      }
      if (index === 1) {
        return "background:linear-gradient(315deg, #fc9842 0%, #fe5f75 74%) !important";
      }
      if (index === 2) {
        return "background:linear-gradient(315deg, #f2cf07 0%, #55d284 74%) !important";
      }
    },
    // togglePlans(index, selectedCard) {
    //   for (let plan = 0; plan < this.cardInfo.length; plan++) {
    //     if (plan == index) {
    //       this.cardInfo[plan].planOpted = true;
    //     } else {
    //       this.cardInfo[plan].planOpted = false;
    //     }
    //   }
    //   this.selectedPlan = selectedCard.id;
    //   this.$emit("selectedPlanEvent", selectedCard);
    // },
    selectPlan(card) {
      this.cardInfo.forEach((plan) => {
        if (plan.id == card.id) {
          plan.planOpted = true;
        } else {
          plan.planOpted = false;
        }
      });
      this.$emit("selectedPlanEvent", card);
      this.selectedPlan = card.id;
      this.$router.push("/register")
    },
    // goToNextPage() {
    //   this.$emit("plansNext", this.selectedPlan);
    // },
    // viewMoreField(cardDescList) {
    //   console.log(cardDescList.length);
    //   this.cardDescListLength = cardDescList.length;
    // },
  },
};
</script>

<style scoped>
.selected-plan {
  border: 3px solid #17a2b8 !important;
}
.btn-primary {
  background: #fff !important;
  color: #17a2b8 !important;
  font-family: "Lato", sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  /* border: 1px solid #17a2b8; */
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 0;
  height: 40px;
  width: 90%;
  left: 5%;
}
h4 {
  left: 8.57%;
  right: 83.7%;
  top: 6.45%;
  bottom: 89.46%;
  background-color: #fcfcfc;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}
.price-title {
  font-family: "Lato", sans-serif !important;
  font-size: 30px !important;
  text-align: center;
  color: #000000;
  font-weight: bold !important;
}
.sub-title {
  left: 2.4%;
  right: 77.53%;
  top: 12.69%;
  bottom: 83.23%;
  font-family: "Lato", sans-serif !important;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.text-muted {
  color: black;
}
.card {
  margin-left: 0;
  margin-right: 0;
  min-height: 506.8px;
  max-width: 350px;
}
.card:focus {
  outline: none;
}
.planSelected {
  font-family: "Lato", sans-serif !important;
  /* border-top: 2px solid #ff7d51 !important; */
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  /* border: solid 1px #e0e3eb; */
  border: none;
  background-color: #fcfcfc;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}
.current-plan {
  /* background: #007bff !important; */
  color: #fff !important;
}
.card-inner-text {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #848484 !important;
}
.card-desc-text {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #363636;
}
.card-group {
  padding-top: 2%;
}
.card-body {
  padding: 0;
  /* padding: 1.25rem !important; */
  /* padding-bottom: 26px !important; */
  /* padding-left: 11px !important; */
  /* padding-top: 24px !important; */
  width: 315px;
  /* padding-right: 11px !important; */
}
.under-line {
  border-bottom: 1px solid #17a2b8;
  width: 50px;
  display: block;
}
.under-line-medium {
  border-bottom: 1px solid #848484;
  width: 250px;
  display: block;
  opacity: 0.5;
}
/* .view-more {
  font-family: "Lato", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: #17a2b8;
  cursor: pointer;
} */
.star {
  font-family: "Lato", sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #6e6e6e;
  vertical-align: super;
}
.desc-item::before {
  content: "";
  background: url("../assets/done.svg");
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 13px;
  margin-top: 5px;
}
#checkbox-group-1 {
  text-align: left !important;
}
::v-deep .planSelected{
  overflow: hidden;
}
::v-deep .planSelected:hover{
  box-shadow: 1px 2px 5px 2px #888888;
}
::v-deep .card-body:hover{
  transform: scaleY(1.03);
  transition: 0.2s linear;
  /* box-shadow: 10px 10px 5px red; */
}
</style>
