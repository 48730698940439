<template>
  <div class="plans">
    <b-navbar></b-navbar>
    <h3 class="text-center pt-2">Flexible Plans and Pricing</h3>
    <hr class="my-2" />
    <div class="plan-section">
      <w-multi-plan-card
        :cardInfo="planInfo"
        :showButtonInCard="true"
        :isSingleCard="false"
        class=""
      />
      <div class="row mb-2   d-flex justify-content-center">
        <button class="cancel-edit" @click="backToHome">Back</button>
        <!-- <button class="save-edit">Next</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../widgets/BrandNavbar"
import MultiPlanCard from "../widgets/MultiPlanCard";
export default {
  components: {
    "b-navbar":Navbar,
    "w-multi-plan-card": MultiPlanCard,
  },

  data() {
    return {
      planInfo: [
        {
          name: "Basic",
          id: "1",
          planOpted: false,
          Rate: 2000,
          Description: "*Price exclusive of service tax.",
          buttonText: "SELECT",
          cardDescList: [
            "1000 Orders",
            "3 Channels",
            "2 Users",
            "1000 SKU",
          ],
        },
        {
          name: "Standard ",
          id: "2",
          planOpted: false,
          Rate: 4000,
          Description: "*Price exclusive of service tax.",
          buttonText: "SELECT",
          cardDescList: [
            "5000 Orders",
            "8 Channels",
            "8 Users",
            "5000 SKU",
          ],
        },
        {
          name: "Premium ",
          id: "3",
          planOpted: false,
          Rate: 15000,
          Description: "*Price exclusive of service tax.",
          buttonText: "SELECT",
          cardDescList: [
            "10000 Orders",
            "20 Channels",
            "10 Users",
            "10000 SKU",
          ],
        },
      ],
    };
  },

  created() {
    // this.getSubPlans();
  },

  methods: {
    // getSubPlans() {
    //   this.plansOptionList = [];
    //   this.planInfo.forEach((element) => {
    //     this.plansOptionList.push({ text: element.name, value: element.id });
    //   });
    //   console.log(this.plansOptionList);
    // },
    backToHome(){
      this.$router.push("/")
    },
  },
};
</script>

<style scoped>
.plans h3 {
  font-family: "Lato", sans-serif !important;
}
.plan-section ::v-deep .card-group {
  padding-top: 0 !important;
}
.plan-section div {
  display: flex;
  justify-content: center;
}
.cancel-edit {
  color: #007bff !important;
  background-color: #fff !important;
  /* margin-right: 20px; */
}
.save-edit,
.cancel-edit {
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 40px;
  border-radius: 5px;
  font-family: "Lato", sans-serif !important;
}
</style>
