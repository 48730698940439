<template>
  <div class="brand-navbar">
    <b-navbar toggleable="lg">
      <b-navbar-brand href="" @click="goToHome" class="logo">
        <span style="font-size:50px; color:#e50914">H</span>ecto<span
          style="font-size:50px; color:#e50914"
          >C</span
        >ommerce
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="w-50">
          <!-- <b-nav-item href="#">About Us</b-nav-item> -->
          <div class="w-100 d-flex justify-content-center align-items-center">
            <p class="mb-0 text-white" style="font-size:14px">Follow Us On</p>
            <div class="d-flex align-items-center pl-2">
              <a
                href="https://www.facebook.com/HectoCommerce-102415571791861"
                target="_blank"
              >
                <img
                  src="../assets/Welcome/facebook.png"
                  alt="facebook"
                  class="social-icons"
                />
              </a>
              <a href="https://www.instagram.com/" target="_blank">
                <img
                  src="../assets/Welcome/instagram.png"
                  alt="instagram"
                  class="social-icons"
                />
              </a>
              <a
                href="https://www.linkedin.com/showcase/hectocommerce/about/"
                target="_blank"
              >
                <img
                  src="../assets/Welcome/linkdin.png"
                  alt="linkdin"
                  class="social-icons"
                />
              </a>
              <a href="https://twitter.com/LOGIN" target="_blank">
                <img
                  src="../assets/Welcome/twitter.png"
                  alt="twitter"
                  class="social-icons"
                />
              </a>
            </div>
          </div>
          <b-nav-item href="" @click="goToLogin">{{login}}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
    props:["login"],
        
    methods:{
        goToLogin(){
            this.$router.push("/login")
        },
        goToHome(){
            this.$router.push("/")
        }
    }
};
</script>

<style scoped>
.brand-navbar ::v-deep #nav-collapse {
  justify-content: flex-end;
}
.brand-navbar ::v-deep .navbar {
  background-color: #141a1f;
}
.brand-navbar .logo {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  font-family: cursive;
  margin-left: 10%;
  cursor: pointer;
}
.brand-navbar ::v-deep .navbar-nav {
  display: flex;
  justify-content: space-evenly;
}
.brand-navbar ::v-deep .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  font-family: proxima-nova, sans-serif !important;
  opacity: 1 !important;
  position: relative;
}
.social-icons {
  border: 1px solid;
  margin: 0 8px;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  transition: 0.3s linear;
}
.social-icons:hover {
  transform: scale(1.2);
  box-shadow: 0 2px 8px 0 #ccc, 0 2px 10px 0 #ccc;
}
::v-deep .navbar-light .navbar-toggler{
  background: #fff;
}
</style>
